//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'salesreport',
  data() {
    return {
      showInnerLoading: false,
      reports: [
        {
          title: 'Profiling statistics',
          name: 'profilingStats',
        },
        {
          title: 'Profiling answers',
          name: 'profilingAnswers',
        },
        {
          title: 'Questions by demonstrator',
          name: 'qByDemostrator',
        },
        {
          title: '34. questions about coffee mach',
          name: 'qAboutCoffeeM',
        },

        {
          title: '36. Positive comments',
          name: 'positiveCom',
        },

        {
          title: '37. Negative comments',
          name: 'negativeCom',
        },

        {
          title: 'Q&A during chat',
          name: 'qaDuringChat',
        },

        {
          title: 'New Q&A',
          name: 'qaNew',
        },

        {
          title: 'Briefing transcript reporting',
          name: 'transcript',
        },
      ],
    };
  },
  computed: {
    ...mapGetters({
      getFilterDate: 'getFilterDate',
      currentAIAccount: 'getCurrentAIAccount',
      currentUser: 'getCurrentUser',
      darkTheme: 'getDarkTheme',
    }),
  },
  methods: {
    ...mapActions({
      generateXMLFile: 'generateXMLFile',
      toggelLoader: 'toggelLoader',
    }),
    downloadExcel(reportType) {
      const that = this;
      that.showInnerLoading = true;
      const data = {
        type: reportType,
        aiAccount: that.currentAIAccount.id,
        startdate: that.getFilterDate.start,
        enddate: that.getFilterDate.end,
      };
      that.generateXMLFile(data);
      setTimeout(() => {
        that.showInnerLoading = false;
      }, 1000);
    },
  },
};
